
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <Confirm ref="confirm"></Confirm>
 *
 * Call it:
 * (this.$refs.confirm as Confirm).open('Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await (this.$refs.confirm as Confirm).open('Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Confirm extends Vue {
  dialog = false
  resolve!: (value: boolean | PromiseLike<boolean>) => void
  reject!: (value: boolean | PromiseLike<boolean>) => void
  message = ''
  showCheckbox = false
  imSure = true
  options = {}

  readonly defaultOptions = {
    title: 'Confirmation required',
    color: 'grey',
    width: 400,
    zIndex: 200,
    icon: 'mdi-delete',
    checkbox: false
  }

  open(message: string, options: Record<string, unknown> = {}): Promise<boolean> {
    this.dialog = true

    this.message = message
    this.options = { ...this.defaultOptions, ...options }
    this.showCheckbox = !!options.checkbox
    this.imSure = !options.checkbox

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  close() {
    this.dialog = false
  }

  agree(): void {
    this.resolve(true)
    this.close()
  }

  cancel(): void {
    this.resolve(false)
    this.close()
  }
}
